
.btn-facebook{background-color: #405D9D; color:#fff; &:hover{color:#fff} }
.btn-instagram{background-color:#E52D27 ; color:#fff; &:hover{color:#fff}}
.btn-youtube{background-color: #C8046C; color:#fff; &:hover{color:#fff}}
.btn-twitter{background-color:#42AEEC; color:#fff; &:hover{color:#fff}}
.btn-google{  background-color:#ff7575; color:#fff; &:hover{color:#fff} }

.btn-icon{ text-align: center;
	padding-left:0.5em; padding-right:0.5em;
	&:hover{
		 background-color:rgba($gray-600, .15);
	}
	&:focus{ box-shadow:none;}
}

.btn-light{ background-color:#fff; border-color:$border-color; 
	i{ color:$gray-500; }
	&:hover{ background-color:#fff;  border-color:$gray-500;  }
}

// .btn{
// 	.text + i{ margin-left:10px; }
// 	i + .text{ margin-left:10px; }
// }

.btn-drag{
	padding:5px 2px; display: inline-block;
	cursor: ns-resize!important;
}


.btn-sm{
	i{ 
		font-size: 1.2rem;
    	line-height: 0.7;
    	vertical-align: bottom;
	}
}
