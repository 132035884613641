/* ==== CUSTOM UI ELEMENTS AND STYLES ====*/

/* ========================
INCLUDE STYLES AND COMPONENTS
===================== */
@import 'ant-design-vue/dist/antd.css';
@import "bootstrap";

@import "variables-custom"; 

/* ================== HELPERS =================== */
@import "ui-assets/base/helpers";

/* ================== BASE =================== */
@import "ui-assets/base/layout";
@import "ui-assets/base/navigation";
@import "ui-assets/base/typography";



/* ================== COMPONENTS =================== */
@import "ui-assets/components/plugin-styles";
@import "ui-assets/components/items";
@import "ui-assets/components/lists";
@import "ui-assets/components/elements.scss";
@import "ui-assets/components/forms";
@import "ui-assets/components/buttons";

/* ================== PAGES =================== */
@import "ui-assets/pages/page-home";

/* ================== DARK =================== */
@import "ui-assets/base/layout-dark";

/* ================== RESPONSIVE =================== */
@import "responsive";

/* ================== CUSTOM FONTS =================== */
@import "material-icon/scss/round.scss";
@import "font-awesome";

/* ================== CUSTOM STYLES =================== */
@import "custom";
