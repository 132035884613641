@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// .classname { include text-truncate(); }


.table{
    thead th {
        color:$text-muted;
        font-weight:400;
    }
}
.table-lg{
	td, th{ padding:0.8rem 1rem; }
}


.screen-overlay {
  width:0%;
  height: 100%;
  z-index: 30;
  position: fixed;
  top: 0;
  left: 0;
  opacity:0;
  visibility:hidden;
  background-color: rgba(34, 34, 34, 0.6);
  transition:opacity .2s linear, visibility .1s, width 1s ease-in;
   }
.screen-overlay.show {
    transition:opacity .5s ease, width 0s;
    opacity:1;
    width:100%;
    visibility:visible;
}


/* =================  DEFINED VARIABLES =================== */
.icon-xs, .icon-sm, .icon-md, .icon-lg{display:inline-flex; align-items:center; justify-content:center; text-align:center; flex-shrink: 0; flex-grow:0; }
.icon-xs{ width:32px;  height:32px;  line-height:32px!important; font-size:16px; }
.icon-sm{ width:48px;  height:48px;  line-height:48px!important; font-size:20px; }
.icon-md{width:60px;  height:60px;  line-height:60px!important; font-size:28px; }
.icon-lg{width:96px;  height:96px;  line-height:96px!important; font-size:42px; }

.img-avatar{
	border-radius:100%; border:3px solid #fff;
}

.img-xs, .img-sm, .img-md, .img-lg{object-fit:cover; flex-shrink: 0; flex-grow:0;}
.img-xs{width:40px; height:40px; }
.img-sm{width:60px; height:60px; }
.img-md{width:112px; height:112px; }
.img-lg{width:196px; height:196px; }


.center-xy{  top:50%; left:50%; position:absolute; transform:translate(-50%, -50%); }
.center-y{top: 50%; position: absolute; transform:translateY(-50%)}
.center-x{left: 50%; position: absolute; transform:translateX(-50%)}