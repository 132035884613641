@charset "UTF-8";

$font-path: '../font/';

$default-family: 'Material Icons Round';
$default-theme: 'round';
$default-filename: 'MaterialIcons-Round';

$extra-themes: (
  
);

$icons: (
  '360': e000,
  '3d_rotation': e001,
  '4k': e002,
  'ac_unit': e004,
  'access_alarm': e005,
  'access_alarms': e007,
  'access_time': e009,
  'accessibility': e00b,
  'accessibility_new': e00c,
  'accessible': e00d,
  'accessible_forward': e00e,
  'account_balance': e00f,
  'account_balance_wallet': e011,
  'account_box': e013,
  'account_circle': e015,
  'account_tree': e017,
  'adb': e019,
  'add': e01a,
  'add_a_photo': e01b,
  'add_alarm': e01d,
  'add_alert': e01f,
  'add_box': e021,
  'add_circle': e023,
  'add_circle_outline': e025,
  'add_comment': e026,
  'add_location': e028,
  'add_photo_alternate': e02a,
  'add_shopping_cart': e02c,
  'add_to_home_screen': e02d,
  'add_to_photos': e02e,
  'add_to_queue': e030,
  'adjust': e032,
  'airline_seat_flat': e033,
  'airline_seat_flat_angled': e035,
  'airline_seat_individual_suite': e037,
  'airline_seat_legroom_extra': e039,
  'airline_seat_legroom_normal': e03a,
  'airline_seat_legroom_reduced': e03b,
  'airline_seat_recline_extra': e03c,
  'airline_seat_recline_normal': e03d,
  'airplanemode_active': e03e,
  'airplanemode_inactive': e03f,
  'airplay': e040,
  'airport_shuttle': e041,
  'alarm': e043,
  'alarm_add': e045,
  'alarm_off': e047,
  'alarm_on': e048,
  'album': e04a,
  'all_inbox': e04c,
  'all_inclusive': e04e,
  'all_out': e04f,
  'alternate_email': e051,
  'amp_stories': e053,
  'android': e055,
  'announcement': e056,
  'apartment': e058,
  'apps': e059,
  'archive': e05a,
  'arrow_back': e05c,
  'arrow_back_ios': e05d,
  'arrow_downward': e05e,
  'arrow_drop_down': e05f,
  'arrow_drop_down_circle': e060,
  'arrow_drop_up': e062,
  'arrow_forward': e063,
  'arrow_forward_ios': e064,
  'arrow_left': e065,
  'arrow_right': e066,
  'arrow_right_alt': e067,
  'arrow_upward': e068,
  'art_track': e069,
  'aspect_ratio': e06a,
  'assessment': e06c,
  'assignment': e06e,
  'assignment_ind': e070,
  'assignment_late': e072,
  'assignment_return': e074,
  'assignment_returned': e076,
  'assignment_turned_in': e078,
  'assistant': e07a,
  'assistant_photo': e07c,
  'atm': e07e,
  'attach_file': e07f,
  'attach_money': e080,
  'attachment': e081,
  'audiotrack': e082,
  'autorenew': e084,
  'av_timer': e085,
  'backspace': e086,
  'backup': e088,
  'ballot': e08a,
  'bar_chart': e08c,
  'barcode': e08d,
  'bathtub': e08e,
  'battery_20': (e090, e091),
  'battery_30': (e092, e093),
  'battery_50': (e094, e095),
  'battery_60': (e096, e097),
  'battery_80': (e098, e099),
  'battery_90': (e09a, e09b),
  'battery_alert': e09c,
  'battery_charging_20': (e09d, e09e),
  'battery_charging_30': (e09f, e0a0),
  'battery_charging_50': (e0a1, e0a2),
  'battery_charging_60': (e0a3, e0a4),
  'battery_charging_80': (e0a5, e0a6),
  'battery_charging_90': (e0a7, e0a8),
  'battery_charging_full': e0a9,
  'battery_full': e0aa,
  'battery_std': e0ab,
  'battery_unknown': e0ac,
  'beach_access': e0ad,
  'beenhere': e0af,
  'block': e0b1,
  'bluetooth': e0b2,
  'bluetooth_audio': e0b3,
  'bluetooth_connected': e0b4,
  'bluetooth_disabled': e0b5,
  'bluetooth_searching': e0b6,
  'blur_circular': e0b7,
  'blur_linear': e0b8,
  'blur_off': e0b9,
  'blur_on': e0ba,
  'book': e0bb,
  'bookmark': e0bd,
  'bookmark_border': e0bf,
  'bookmarks': e0c0,
  'border_all': e0c2,
  'border_bottom': e0c3,
  'border_clear': e0c4,
  'border_color': (e0c5, e0c6),
  'border_horizontal': e0c7,
  'border_inner': e0c8,
  'border_left': e0c9,
  'border_outer': e0ca,
  'border_right': e0cb,
  'border_style': e0cc,
  'border_top': e0cd,
  'border_vertical': e0ce,
  'branding_watermark': e0cf,
  'brightness_1': e0d1,
  'brightness_2': e0d3,
  'brightness_3': e0d5,
  'brightness_4': e0d7,
  'brightness_5': e0d9,
  'brightness_6': e0db,
  'brightness_7': e0dd,
  'brightness_auto': e0df,
  'brightness_high': e0e1,
  'brightness_low': e0e3,
  'brightness_medium': e0e5,
  'broken_image': e0e7,
  'brush': e0e9,
  'bubble_chart': e0eb,
  'bug_report': e0ed,
  'build': e0ef,
  'burst_mode': e0f1,
  'business': e0f3,
  'business_center': e0f5,
  'cached': e0f7,
  'cake': e0f8,
  'calendar_today': e0fa,
  'calendar_view_day': e0fc,
  'call': e0fe,
  'call_end': e100,
  'call_made': e102,
  'call_merge': e103,
  'call_missed': e104,
  'call_missed_outgoing': e105,
  'call_received': e106,
  'call_split': e107,
  'call_to_action': e108,
  'camera': e10a,
  'camera_alt': e10c,
  'camera_enhance': e10e,
  'camera_front': e110,
  'camera_rear': e112,
  'camera_roll': e114,
  'cancel': e116,
  'cancel_presentation': e118,
  'cancel_schedule_send': e11a,
  'card_giftcard': e11c,
  'card_membership': e11e,
  'card_travel': e120,
  'casino': e122,
  'cast': e124,
  'cast_connected': e125,
  'cast_for_education': e127,
  'category': e128,
  'cell_wifi': (e12a, e12b),
  'center_focus_strong': e12c,
  'center_focus_weak': e12e,
  'change_history': e130,
  'chat': e132,
  'chat_bubble': e134,
  'chat_bubble_outline': e136,
  'check': e137,
  'check_box': e138,
  'check_box_outline_blank': e13a,
  'check_circle': e13b,
  'check_circle_outline': e13d,
  'chevron_left': e13e,
  'chevron_right': e13f,
  'child_care': e140,
  'child_friendly': e142,
  'chrome_reader_mode': e144,
  'class': e146,
  'clear': e148,
  'clear_all': e149,
  'close': e14a,
  'closed_caption': e14b,
  'cloud': e14d,
  'cloud_circle': e14f,
  'cloud_done': e151,
  'cloud_download': e153,
  'cloud_off': e155,
  'cloud_queue': e157,
  'cloud_upload': e159,
  'code': e15b,
  'collections': e15c,
  'collections_bookmark': e15e,
  'color_lens': e160,
  'colorize': e162,
  'comment': e164,
  'commute': e166,
  'compare': e167,
  'compare_arrows': e169,
  'compass_calibration': e16a,
  'computer': e16c,
  'confirmation_number': e16e,
  'contact_mail': e170,
  'contact_phone': e172,
  'contact_support': e174,
  'contactless': e176,
  'contacts': e178,
  'content_copy': e17a,
  'content_cut': e17c,
  'content_paste': e17e,
  'control_camera': e180,
  'control_point': e181,
  'control_point_duplicate': e183,
  'copyright': e185,
  'create': e187,
  'create_new_folder': e189,
  'credit_card': e18b,
  'crop': e18d,
  'crop_16_9': e18e,
  'crop_3_2': e18f,
  'crop_5_4': e190,
  'crop_7_5': e191,
  'crop_din': e192,
  'crop_free': e193,
  'crop_landscape': e194,
  'crop_original': e195,
  'crop_portrait': e196,
  'crop_rotate': e197,
  'crop_square': e198,
  'dashboard': e199,
  'data_usage': e19b,
  'date_range': e19c,
  'deck': e19e,
  'dehaze': e1a0,
  'delete': e1a1,
  'delete_forever': e1a3,
  'delete_outline': e1a5,
  'delete_sweep': e1a6,
  'departure_board': e1a8,
  'description': e1aa,
  'desktop_access_disabled': e1ac,
  'desktop_mac': e1ae,
  'desktop_windows': e1b0,
  'details': e1b2,
  'developer_board': e1b4,
  'developer_mode': e1b6,
  'device_hub': e1b7,
  'device_unknown': e1b8,
  'devices': e1ba,
  'devices_other': e1bc,
  'dialer_sip': e1be,
  'dialpad': e1c0,
  'directions': e1c1,
  'directions_bike': e1c3,
  'directions_boat': e1c4,
  'directions_bus': e1c6,
  'directions_car': e1c8,
  'directions_railway': e1ca,
  'directions_run': e1cc,
  'directions_subway': e1cd,
  'directions_transit': e1cf,
  'directions_walk': e1d1,
  'disc_full': e1d2,
  'divide': e1d4,
  'dns': e1d6,
  'do_not_disturb': e1d8,
  'do_not_disturb_alt': e1da,
  'do_not_disturb_off': e1dc,
  'dock': e1de,
  'domain': e1e0,
  'domain_disabled': e1e2,
  'done': e1e4,
  'done_all': e1e5,
  'done_outline': e1e6,
  'donut_large': e1e7,
  'donut_small': e1e8,
  'double_arrow': e1ea,
  'drafts': e1eb,
  'drag_handle': e1ed,
  'drag_indicator': e1ee,
  'drive_eta': e1ef,
  'duo': e1f1,
  'dvr': e1f2,
  'dynamic_feed': e1f4,
  'eco': e1f6,
  'edit': e1f8,
  'edit_attributes': e1fa,
  'edit_location': e1fc,
  'eject': e1fe,
  'email': e200,
  'emoji_emotions': e202,
  'emoji_events': e204,
  'emoji_flags': e206,
  'emoji_food_beverage': e208,
  'emoji_nature': e20a,
  'emoji_objects': e20c,
  'emoji_people': e20e,
  'emoji_symbols': e20f,
  'emoji_transportation': e210,
  'enhanced_encryption': e211,
  'equalizer': e213,
  'equals': e214,
  'error': e215,
  'error_outline': e217,
  'euro': e218,
  'euro_symbol': e219,
  'ev_station': e21a,
  'event': e21c,
  'event_available': e21e,
  'event_busy': e220,
  'event_note': e222,
  'event_seat': e224,
  'exit_to_app': e226,
  'expand_less': e227,
  'expand_more': e228,
  'explicit': e229,
  'explore': e22b,
  'explore_off': e22d,
  'exposure': e22f,
  'exposure_neg_1': e231,
  'exposure_neg_2': e232,
  'exposure_plus_1': e233,
  'exposure_plus_2': e234,
  'exposure_zero': e235,
  'extension': e236,
  'face': e238,
  'fast_forward': e23a,
  'fast_rewind': e23c,
  'fastfood': e23e,
  'favorite': e240,
  'favorite_border': e242,
  'featured_play_list': e243,
  'featured_video': e245,
  'feedback': e247,
  'fiber_dvr': e249,
  'fiber_manual_record': e24b,
  'fiber_new': e24d,
  'fiber_pin': e24f,
  'fiber_smart_record': e251,
  'file_copy': e253,
  'file_upload': e255,
  'filter': e257,
  'filter_1': e259,
  'filter_2': e25b,
  'filter_3': e25d,
  'filter_4': e25f,
  'filter_5': e261,
  'filter_6': e263,
  'filter_7': e265,
  'filter_8': e267,
  'filter_9': e269,
  'filter_9_plus': e26b,
  'filter_b_and_w': e26d,
  'filter_center_focus': e26f,
  'filter_drama': e270,
  'filter_frames': e272,
  'filter_hdr': e274,
  'filter_list': e276,
  'filter_none': e277,
  'filter_tilt_shift': e279,
  'filter_vintage': e27a,
  'find_in_page': e27c,
  'find_replace': e27e,
  'fingerprint': e27f,
  'fireplace': e280,
  'first_page': e282,
  'fitness_center': e283,
  'flag': e284,
  'flare': e286,
  'flash_auto': e287,
  'flash_off': e288,
  'flash_on': e289,
  'flight': e28a,
  'flight_land': e28b,
  'flight_takeoff': e28c,
  'flip': e28d,
  'flip_camera_android': e28e,
  'flip_camera_ios': e290,
  'flip_to_back': e292,
  'flip_to_front': e293,
  'folder': e294,
  'folder_open': e296,
  'folder_shared': e298,
  'folder_special': e29a,
  'font_download': e29c,
  'format_align_center': e29e,
  'format_align_justify': e29f,
  'format_align_left': e2a0,
  'format_align_right': e2a1,
  'format_bold': e2a2,
  'format_clear': e2a3,
  'format_color_fill': (e2a4, e2a5),
  'format_color_reset': e2a6,
  'format_color_text': (e2a8, e2a9),
  'format_indent_decrease': e2aa,
  'format_indent_increase': e2ab,
  'format_italic': e2ac,
  'format_line_spacing': e2ad,
  'format_list_bulleted': e2ae,
  'format_list_numbered': e2af,
  'format_list_numbered_rtl': e2b0,
  'format_paint': e2b1,
  'format_quote': e2b3,
  'format_shapes': e2b5,
  'format_size': e2b7,
  'format_strikethrough': e2b8,
  'format_textdirection_l_to_r': e2b9,
  'format_textdirection_r_to_l': e2bb,
  'format_underlined': e2bd,
  'forum': e2be,
  'forward': e2c0,
  'forward_10': e2c2,
  'forward_30': e2c3,
  'forward_5': e2c4,
  'free_breakfast': e2c5,
  'fullscreen': e2c7,
  'fullscreen_exit': e2c8,
  'functions': e2c9,
  'g_translate': e2ca,
  'gamepad': e2cb,
  'games': e2cd,
  'gavel': e2cf,
  'gesture': e2d0,
  'get_app': e2d1,
  'gif': e2d3,
  'golf_course': e2d5,
  'gps_fixed': e2d7,
  'gps_not_fixed': e2d9,
  'gps_off': e2da,
  'grade': e2db,
  'gradient': e2dd,
  'grain': e2de,
  'graphic_eq': e2df,
  'greater_than': e2e0,
  'greater_than_equal': e2e1,
  'grid_off': e2e2,
  'grid_on': e2e4,
  'group': e2e6,
  'group_add': e2e8,
  'group_work': e2ea,
  'hd': e2ec,
  'hdr_off': e2ee,
  'hdr_on': e2ef,
  'hdr_strong': e2f0,
  'hdr_weak': e2f2,
  'headset': e2f4,
  'headset_mic': e2f6,
  'healing': e2f8,
  'hearing': e2fa,
  'height': e2fb,
  'help': e2fc,
  'help_outline': e2fe,
  'high_quality': e2ff,
  'highlight': e301,
  'highlight_off': e303,
  'history': e305,
  'home': e306,
  'home_work': e308,
  'horizontal_split': e30a,
  'hot_tub': e30c,
  'hotel': e30d,
  'hourglass_empty': e30f,
  'hourglass_full': e310,
  'house': e312,
  'how_to_reg': e314,
  'how_to_vote': e316,
  'http': e318,
  'https': e319,
  'image': e31b,
  'image_aspect_ratio': e31d,
  'image_search': e31f,
  'import_contacts': e321,
  'import_export': e323,
  'important_devices': e324,
  'inbox': e326,
  'indeterminate_check_box': e328,
  'info': e32a,
  'input': e32c,
  'insert_chart': e32d,
  'insert_chart_outlined': e32f,
  'insert_comment': e330,
  'insert_drive_file': e332,
  'insert_emoticon': e334,
  'insert_invitation': e336,
  'insert_link': e338,
  'insert_photo': e339,
  'invert_colors': e33b,
  'invert_colors_off': e33d,
  'iso': e33f,
  'keyboard': e341,
  'keyboard_arrow_down': e343,
  'keyboard_arrow_left': e344,
  'keyboard_arrow_right': e345,
  'keyboard_arrow_up': e346,
  'keyboard_backspace': e347,
  'keyboard_capslock': e348,
  'keyboard_hide': e349,
  'keyboard_return': e34b,
  'keyboard_tab': e34c,
  'keyboard_voice': e34d,
  'king_bed': e34f,
  'kitchen': e351,
  'label': e353,
  'label_important': e355,
  'label_off': e357,
  'landscape': e359,
  'language': e35b,
  'laptop': e35d,
  'laptop_chromebook': e35f,
  'laptop_mac': e361,
  'laptop_windows': e363,
  'last_page': e365,
  'launch': e366,
  'layers': e367,
  'layers_clear': e369,
  'leak_add': e36b,
  'leak_remove': e36c,
  'lens': e36d,
  'less_than': e36f,
  'less_than_equal': e370,
  'library_add': e371,
  'library_books': e373,
  'library_music': e375,
  'lightbulb': e377,
  'line_style': e379,
  'line_weight': e37a,
  'linear_scale': e37b,
  'link': e37c,
  'link_off': e37e,
  'linked_camera': e37f,
  'list': e381,
  'list_alt': e382,
  'live_help': e384,
  'live_tv': e386,
  'local_activity': e388,
  'local_airport': e38a,
  'local_atm': e38b,
  'local_bar': e38d,
  'local_cafe': e38f,
  'local_car_wash': e391,
  'local_convenience_store': e393,
  'local_dining': e395,
  'local_drink': e396,
  'local_florist': e398,
  'local_gas_station': e39a,
  'local_grocery_store': e39c,
  'local_hospital': e39e,
  'local_hotel': e3a0,
  'local_laundry_service': e3a2,
  'local_library': e3a4,
  'local_mall': e3a6,
  'local_movies': e3a8,
  'local_offer': e3aa,
  'local_parking': e3ac,
  'local_pharmacy': e3ad,
  'local_phone': e3af,
  'local_pizza': e3b1,
  'local_play': e3b3,
  'local_post_office': e3b5,
  'local_printshop': e3b7,
  'local_see': e3b9,
  'local_shipping': e3bb,
  'local_taxi': e3bd,
  'location_city': e3bf,
  'location_disabled': e3c0,
  'location_off': e3c1,
  'location_on': e3c2,
  'location_searching': e3c4,
  'lock': e3c5,
  'lock_open': e3c7,
  'log_in': e3c9,
  'log_out': e3ca,
  'looks': e3cb,
  'looks_3': e3cc,
  'looks_4': e3ce,
  'looks_5': e3d0,
  'looks_6': e3d2,
  'looks_one': e3d4,
  'looks_two': e3d6,
  'loop': e3d8,
  'loupe': e3d9,
  'low_priority': e3db,
  'loyalty': e3dc,
  'mail': e3de,
  'mail_outline': e3e0,
  'map': e3e1,
  'markunread': e3e3,
  'markunread_mailbox': e3e5,
  'maximize': e3e7,
  'meeting_room': e3e8,
  'memory': e3ea,
  'menu': e3ec,
  'menu_book': e3ed,
  'menu_open': e3ef,
  'merge_type': e3f0,
  'message': e3f1,
  'mic': e3f3,
  'mic_none': e3f5,
  'mic_off': e3f7,
  'minimize': e3f9,
  'minus': e3fa,
  'missed_video_call': e3fb,
  'mms': e3fd,
  'mobile_friendly': e3ff,
  'mobile_off': e400,
  'mobile_screen_share': e401,
  'mode_comment': e403,
  'monetization_on': e405,
  'money': e407,
  'money_off': e409,
  'monochrome_photos': e40a,
  'mood': e40c,
  'mood_bad': e40e,
  'more': e410,
  'more_horiz': e412,
  'more_vert': e413,
  'motorcycle': e414,
  'mouse': e416,
  'move_to_inbox': e418,
  'movie': e41a,
  'movie_creation': e41c,
  'movie_filter': e41e,
  'multiline_chart': e420,
  'museum': e421,
  'music_note': e423,
  'music_off': e425,
  'music_video': e427,
  'my_location': e429,
  'nature': e42b,
  'nature_people': e42d,
  'navigate_before': e42f,
  'navigate_next': e430,
  'navigation': e431,
  'near_me': e433,
  'network_cell': (e435, e436),
  'network_check': e437,
  'network_locked': e438,
  'network_wifi': (e439, e43a),
  'new_releases': e43b,
  'next_week': e43d,
  'nfc': e43f,
  'nights_stay': e440,
  'no_encryption': e442,
  'no_meeting_room': e444,
  'no_sim': e446,
  'not_equal': e448,
  'not_interested': e449,
  'not_listed_location': e44a,
  'note': e44c,
  'note_add': e44e,
  'notes': e450,
  'notification_important': e451,
  'notifications': e453,
  'notifications_active': e455,
  'notifications_none': e457,
  'notifications_off': e459,
  'notifications_paused': e45b,
  'offline_bolt': e45d,
  'offline_pin': e45f,
  'ondemand_video': e461,
  'opacity': e463,
  'open_in_browser': e465,
  'open_in_new': e466,
  'open_with': e467,
  'outdoor_grill': e468,
  'outlined_flag': e46a,
  'pages': e46b,
  'pageview': e46d,
  'palette': e46f,
  'pan_tool': e471,
  'panorama': e473,
  'panorama_fish_eye': e475,
  'panorama_horizontal': e477,
  'panorama_vertical': e479,
  'panorama_wide_angle': e47b,
  'party_mode': e47d,
  'pause': e47f,
  'pause_circle_filled': e480,
  'pause_circle_outline': e482,
  'pause_presentation': e483,
  'payment': e485,
  'people': e487,
  'people_alt': e489,
  'people_outline': e48b,
  'percentage': e48d,
  'perm_camera_mic': e48f,
  'perm_contact_calendar': e491,
  'perm_data_setting': e493,
  'perm_device_information': e494,
  'perm_identity': e496,
  'perm_media': e498,
  'perm_phone_msg': e49a,
  'perm_scan_wifi': e49c,
  'person': e49e,
  'person_add': e4a0,
  'person_add_disabled': e4a2,
  'person_outline': e4a4,
  'person_pin': e4a6,
  'person_pin_circle': e4a8,
  'personal_video': e4aa,
  'pets': e4ac,
  'phone': e4ad,
  'phone_android': e4af,
  'phone_bluetooth_speaker': e4b1,
  'phone_callback': e4b3,
  'phone_disabled': e4b5,
  'phone_enabled': e4b6,
  'phone_forwarded': e4b7,
  'phone_in_talk': e4b9,
  'phone_iphone': e4bb,
  'phone_locked': e4bd,
  'phone_missed': e4bf,
  'phone_paused': e4c1,
  'phonelink': e4c3,
  'phonelink_erase': e4c5,
  'phonelink_lock': e4c6,
  'phonelink_off': e4c7,
  'phonelink_ring': e4c9,
  'phonelink_setup': e4cb,
  'photo': e4cc,
  'photo_album': e4ce,
  'photo_camera': e4d0,
  'photo_filter': e4d2,
  'photo_library': e4d3,
  'photo_size_select_actual': e4d5,
  'photo_size_select_large': e4d7,
  'photo_size_select_small': e4d8,
  'picture_as_pdf': e4d9,
  'picture_in_picture': e4db,
  'picture_in_picture_alt': e4dd,
  'pie_chart': e4df,
  'pin': e4e1,
  'pin_drop': e4e3,
  'pin_off': e4e5,
  'place': e4e7,
  'play_arrow': e4e9,
  'play_circle_filled': e4eb,
  'play_circle_filled_white': e4ed,
  'play_circle_outline': e4ef,
  'play_for_work': e4f0,
  'playlist_add': e4f1,
  'playlist_add_check': e4f2,
  'playlist_play': e4f3,
  'plus': e4f4,
  'plus_minus': e4f5,
  'plus_minus_alt': e4f6,
  'plus_one': e4f7,
  'policy': e4f8,
  'poll': e4fa,
  'polymer': e4fc,
  'pool': e4fd,
  'portable_wifi_off': e4ff,
  'portrait': e500,
  'post_add': e502,
  'power': e503,
  'power_input': e505,
  'power_off': e506,
  'power_settings_new': e508,
  'pregnant_woman': e509,
  'present_to_all': e50a,
  'print': e50c,
  'print_disabled': e50e,
  'priority_high': e510,
  'public': e511,
  'publish': e513,
  'qrcode': e515,
  'query_builder': e517,
  'question_answer': e519,
  'queue': e51b,
  'queue_music': e51d,
  'queue_play_next': e51f,
  'radio': e520,
  'radio_button_checked': e522,
  'radio_button_unchecked': e523,
  'rate_review': e524,
  'receipt': e526,
  'recent_actors': e528,
  'record_voice_over': e52a,
  'redeem': e52c,
  'redo': e52e,
  'refresh': e52f,
  'remove': e530,
  'remove_circle': e531,
  'remove_circle_outline': e533,
  'remove_from_queue': e534,
  'remove_red_eye': e536,
  'remove_shopping_cart': e538,
  'reorder': e53a,
  'repeat': e53b,
  'repeat_one': e53c,
  'replay': e53d,
  'replay_10': e53e,
  'replay_30': e53f,
  'replay_5': e540,
  'reply': e541,
  'reply_all': e542,
  'report': e543,
  'report_off': e545,
  'report_problem': e547,
  'restaurant': e549,
  'restaurant_menu': e54a,
  'restore': e54b,
  'restore_from_trash': e54c,
  'restore_page': e54e,
  'ring_volume': e550,
  'rocket': e552,
  'room': e554,
  'room_service': e556,
  'rotate_90_degrees_ccw': e558,
  'rotate_left': e55a,
  'rotate_right': e55b,
  'rounded_corner': e55c,
  'router': e55d,
  'rowing': e55f,
  'rss_feed': e560,
  'rv_hookup': e561,
  'satellite': e563,
  'save': e565,
  'save_alt': e567,
  'scanner': e568,
  'scatter_plot': e56a,
  'schedule': e56c,
  'school': e56e,
  'score': e570,
  'screen_lock_landscape': e572,
  'screen_lock_portrait': e574,
  'screen_lock_rotation': e576,
  'screen_rotation': e577,
  'screen_share': e579,
  'sd_card': e57b,
  'sd_storage': e57d,
  'search': e57f,
  'security': e580,
  'select_all': e582,
  'send': e583,
  'sentiment_dissatisfied': e585,
  'sentiment_neutral': e587,
  'sentiment_satisfied': e589,
  'sentiment_satisfied_alt': e58b,
  'sentiment_slightly_dissatisfied': e58d,
  'sentiment_very_dissatisfied': e58f,
  'sentiment_very_satisfied': e591,
  'settings': e593,
  'settings_applications': e595,
  'settings_backup_restore': e597,
  'settings_bluetooth': e598,
  'settings_brightness': e599,
  'settings_cell': e59b,
  'settings_ethernet': e59d,
  'settings_input_antenna': e59e,
  'settings_input_component': e59f,
  'settings_input_composite': e5a1,
  'settings_input_hdmi': e5a3,
  'settings_input_svideo': e5a5,
  'settings_overscan': e5a7,
  'settings_phone': e5a9,
  'settings_power': e5ab,
  'settings_remote': e5ac,
  'settings_system_daydream': e5ae,
  'settings_voice': e5b0,
  'share': e5b2,
  'shop': e5b4,
  'shop_two': e5b6,
  'shopping_basket': e5b8,
  'shopping_cart': e5ba,
  'short_text': e5bc,
  'show_chart': e5bd,
  'shuffle': e5be,
  'shutter_speed': e5bf,
  'signal_cellular_0_bar': (e5c1, e5c2),
  'signal_cellular_1_bar': (e5c3, e5c4),
  'signal_cellular_2_bar': (e5c5, e5c6),
  'signal_cellular_3_bar': (e5c7, e5c8),
  'signal_cellular_4_bar': e5c9,
  'signal_cellular_alt': e5ca,
  'signal_cellular_connected_no_internet_0_bar': (e5cb, e5cc),
  'signal_cellular_connected_no_internet_1_bar': (e5cd, e5ce),
  'signal_cellular_connected_no_internet_2_bar': (e5cf, e5d0),
  'signal_cellular_connected_no_internet_3_bar': (e5d1, e5d2),
  'signal_cellular_connected_no_internet_4_bar': e5d3,
  'signal_cellular_no_sim': e5d4,
  'signal_cellular_null': e5d6,
  'signal_cellular_off': e5d7,
  'signal_wifi_0_bar': (e5d8, e5d9),
  'signal_wifi_1_bar': (e5da, e5db),
  'signal_wifi_1_bar_lock': (e5dc, e5dd),
  'signal_wifi_2_bar': (e5de, e5df),
  'signal_wifi_2_bar_lock': (e5e0, e5e1),
  'signal_wifi_3_bar': (e5e2, e5e3),
  'signal_wifi_3_bar_lock': (e5e4, e5e5),
  'signal_wifi_4_bar': e5e6,
  'signal_wifi_4_bar_lock': e5e7,
  'signal_wifi_off': e5e8,
  'sim_card': e5e9,
  'sim_card_alert': e5eb,
  'single_bed': e5ed,
  'skip_next': e5ef,
  'skip_previous': e5f1,
  'slideshow': e5f3,
  'slow_motion_video': e5f5,
  'smartphone': e5f6,
  'smoke_free': e5f8,
  'smoking_rooms': e5f9,
  'sms': e5fb,
  'sms_failed': e5fd,
  'snooze': e5ff,
  'sort': e600,
  'sort_by_alpha': e601,
  'spa': e602,
  'space_bar': e604,
  'speaker': e605,
  'speaker_group': e607,
  'speaker_notes': e609,
  'speaker_notes_off': e60b,
  'speaker_phone': e60d,
  'speed': e60f,
  'spellcheck': e610,
  'sports': e611,
  'sports_baseball': e612,
  'sports_basketball': e614,
  'sports_cricket': e616,
  'sports_esports': e618,
  'sports_football': e61a,
  'sports_golf': e61c,
  'sports_handball': e61e,
  'sports_hockey': e61f,
  'sports_kabaddi': e620,
  'sports_mma': e621,
  'sports_motorsports': e623,
  'sports_rugby': e625,
  'sports_soccer': e627,
  'sports_tennis': e629,
  'sports_volleyball': e62a,
  'square_foot': e62c,
  'star': e62e,
  'star_border': e630,
  'star_half': e631,
  'star_rate': e632,
  'stars': e633,
  'stay_current_landscape': e635,
  'stay_current_portrait': e637,
  'stay_primary_landscape': e639,
  'stay_primary_portrait': e63b,
  'stop': e63d,
  'stop_circle': e63f,
  'stop_screen_share': e641,
  'storage': e643,
  'store': e644,
  'store_mall_directory': e646,
  'storefront': e648,
  'straighten': e64a,
  'streetview': e64c,
  'strikethrough_s': e64d,
  'style': e64e,
  'subdirectory_arrow_left': e650,
  'subdirectory_arrow_right': e651,
  'subject': e652,
  'subscriptions': e653,
  'subtitles': e655,
  'subway': e657,
  'supervised_user_circle': e659,
  'supervisor_account': e65b,
  'surround_sound': e65d,
  'swap_calls': e65f,
  'swap_horiz': e660,
  'swap_horizontal_circle': e661,
  'swap_vert': e663,
  'swap_vertical_circle': e664,
  'switch_camera': e666,
  'switch_video': e668,
  'sync': e66a,
  'sync_alt': e66b,
  'sync_disabled': e66c,
  'sync_problem': e66d,
  'system_update': e66e,
  'system_update_alt': e670,
  'tab': e671,
  'tab_unselected': e672,
  'table_chart': e673,
  'tablet': e675,
  'tablet_android': e677,
  'tablet_mac': e679,
  'tag_faces': e67b,
  'tap_and_play': e67d,
  'terrain': e67e,
  'text_fields': e680,
  'text_format': e681,
  'text_rotate_up': e682,
  'text_rotate_vertical': e683,
  'text_rotation_angledown': e684,
  'text_rotation_angleup': e685,
  'text_rotation_down': e686,
  'text_rotation_none': e687,
  'textsms': e688,
  'texture': e68a,
  'theaters': e68b,
  'thumb_down': e68d,
  'thumb_down_alt': e68f,
  'thumb_up': e691,
  'thumb_up_alt': e693,
  'thumbs_up_down': e695,
  'time_to_leave': e697,
  'timelapse': e699,
  'timeline': e69b,
  'timer': e69c,
  'timer_10': e69e,
  'timer_3': e69f,
  'timer_off': e6a0,
  'title': e6a2,
  'toc': e6a3,
  'today': e6a4,
  'toggle_off': e6a6,
  'toggle_on': e6a8,
  'toll': e6aa,
  'tonality': e6ac,
  'touch_app': e6ae,
  'toys': e6b0,
  'track_changes': e6b2,
  'traffic': e6b3,
  'train': e6b5,
  'tram': e6b7,
  'transfer_within_a_station': e6b9,
  'transform': e6ba,
  'transit_enterexit': e6bb,
  'translate': e6bc,
  'trending_down': e6bd,
  'trending_flat': e6be,
  'trending_up': e6bf,
  'trip_origin': e6c0,
  'tune': e6c1,
  'turned_in': e6c2,
  'turned_in_not': e6c4,
  'tv': e6c5,
  'tv_off': e6c7,
  'unarchive': e6c9,
  'undo': e6cb,
  'unfold_less': e6cc,
  'unfold_more': e6cd,
  'unsubscribe': e6ce,
  'update': e6d0,
  'usb': e6d1,
  'verified_user': e6d2,
  'vertical_align_bottom': e6d4,
  'vertical_align_center': e6d5,
  'vertical_align_top': e6d6,
  'vertical_split': e6d7,
  'vibration': e6d9,
  'video_call': e6db,
  'video_label': e6dd,
  'video_library': e6df,
  'videocam': e6e1,
  'videocam_off': e6e3,
  'videogame_asset': e6e5,
  'view_agenda': e6e7,
  'view_array': e6e9,
  'view_carousel': e6eb,
  'view_column': e6ed,
  'view_comfy': e6ef,
  'view_compact': e6f1,
  'view_day': e6f3,
  'view_headline': e6f5,
  'view_list': e6f6,
  'view_module': e6f8,
  'view_quilt': e6fa,
  'view_stream': e6fc,
  'view_week': e6fe,
  'vignette': e700,
  'visibility': e702,
  'visibility_off': e704,
  'voice_chat': e706,
  'voice_over_off': e708,
  'voicemail': e70a,
  'volume_down': e70b,
  'volume_mute': e70d,
  'volume_off': e70f,
  'volume_up': e711,
  'vpn_key': e713,
  'vpn_lock': e715,
  'wallpaper': e717,
  'warning': e718,
  'watch': e71a,
  'watch_later': e71c,
  'waves': e71e,
  'wb_auto': e71f,
  'wb_cloudy': e721,
  'wb_incandescent': e723,
  'wb_iridescent': e725,
  'wb_sunny': e727,
  'wc': e729,
  'web': e72a,
  'web_asset': e72c,
  'weekend': e72e,
  'whatshot': e730,
  'where_to_vote': e732,
  'widgets': e734,
  'wifi': e736,
  'wifi_lock': e737,
  'wifi_off': e738,
  'wifi_tethering': e739,
  'work': e73a,
  'work_off': e73c,
  'work_outline': e73e,
  'wrap_text': e73f,
  'youtube_searched_for': e740,
  'zoom_in': e741,
  'zoom_out': e742,
  'zoom_out_map': e743,
  'add_ic_call': e744,
  'library_add_check': e746,
  'star_outline': e748,
  'two_wheeler': e749,
  '5g': e74a,
  'ad_units': e74b,
  'add_business': e74d,
  'add_location_alt': e74f,
  'add_road': e751,
  'addchart': e752,
  'admin_panel_settings': e753,
  'agriculture': e755,
  'alt_route': e757,
  'analytics': e758,
  'anchor': e75a,
  'api': e75b,
  'app_blocking': e75c,
  'app_settings_alt': e75e,
  'architecture': e760,
  'arrow_circle_down': e761,
  'arrow_circle_up': e763,
  'article': e765,
  'attach_email': e767,
  'auto_delete': e768,
  'baby_changing_station': e76a,
  'backpack': e76b,
  'backup_table': e76d,
  'batch_prediction': e76f,
  'bedtime': e771,
  'bike_scooter': e773,
  'biotech': e774,
  'browser_not_supported': e776,
  'build_circle': e777,
  'calculate': e779,
  'campaign': e77b,
  'charging_station': e77d,
  'checkroom': e77f,
  'cleaning_services': e780,
  'close_fullscreen': e782,
  'comment_bank': e783,
  'construction': e785,
  'corporate_fare': e786,
  'design_services': e788,
  'directions_off': e78a,
  'do_not_step': e78b,
  'do_not_touch': e78d,
  'domain_verification': e78f,
  'dry': e791,
  'dynamic_form': e793,
  'edit_road': e795,
  'electric_bike': e797,
  'electric_car': e798,
  'electric_moped': e79a,
  'electric_scooter': e79c,
  'electrical_services': e79d,
  'elevator': e79e,
  'engineering': e7a0,
  'escalator': e7a2,
  'escalator_warning': e7a4,
  'fact_check': e7a5,
  'family_restroom': e7a7,
  'filter_alt': e7a8,
  'flaky': e7aa,
  'forward_to_inbox': e7ab,
  'grading': e7ad,
  'handyman': e7ae,
  'hearing_disabled': e7b0,
  'help_center': e7b1,
  'highlight_alt': e7b3,
  'history_edu': e7b4,
  'history_toggle_off': e7b6,
  'home_repair_service': e7b7,
  'horizontal_rule': e7b9,
  'hourglass_bottom': e7ba,
  'hourglass_disabled': e7bc,
  'hourglass_top': e7bd,
  'hvac': e7bf,
  'image_not_supported': e7c1,
  'insights': e7c3,
  'integration_instructions': e7c4,
  'legend_toggle': e7c6,
  'login': e7c7,
  'maps_ugc': e7c8,
  'mark_chat_read': e7ca,
  'mark_chat_unread': e7cc,
  'mark_email_read': e7ce,
  'mark_email_unread': e7d0,
  'mediation': e7d2,
  'medical_services': e7d3,
  'military_tech': e7d5,
  'miscellaneous_services': e7d7,
  'model_training': e7d8,
  'moped': e7d9,
  'more_time': e7db,
  'multiple_stop': e7dc,
  'nat': e7dd,
  'next_plan': e7df,
  'no_cell': e7e1,
  'no_drinks': e7e3,
  'no_flash': e7e5,
  'no_food': e7e7,
  'no_photography': e7e9,
  'no_stroller': e7eb,
  'not_accessible': e7ed,
  'not_started': e7ee,
  'online_prediction': e7f0,
  'open_in_full': e7f1,
  'outlet': e7f2,
  'payments': e7f4,
  'pedal_bike': e7f6,
  'pending': e7f7,
  'pending_actions': e7f9,
  'person_add_alt_1': e7fb,
  'person_remove': e7fd,
  'person_remove_alt_1': e7ff,
  'person_search': e801,
  'pest_control': e803,
  'pest_control_rodent': e805,
  'plagiarism': e807,
  'plumbing': e809,
  'point_of_sale': e80a,
  'preview': e80c,
  'privacy_tip': e80e,
  'psychology': e810,
  'public_off': e812,
  'push_pin': e814,
  'qr_code': e816,
  'quickreply': e818,
  'read_more': e81a,
  'receipt_long': e81b,
  'request_quote': e81d,
  'room_preferences': e81f,
  'rule': e821,
  'rule_folder': e822,
  'run_circle': e824,
  'science': e826,
  'search_off': e828,
  'self_improvement': e829,
  'sensor_door': e82a,
  'sensor_window': e82c,
  'shopping_bag': e82e,
  'smart_button': e830,
  'snippet_folder': e831,
  'soap': e833,
  'source': e835,
  'stairs': e837,
  'stroller': e839,
  'subscript': e83b,
  'subtitles_off': e83c,
  'superscript': e83e,
  'support': e83f,
  'support_agent': e841,
  'switch_left': e842,
  'switch_right': e844,
  'table_rows': e846,
  'table_view': e848,
  'text_snippet': e84a,
  'topic': e84c,
  'tour': e84e,
  'tty': e850,
  'umbrella': e852,
  'upgrade': e854,
  'verified': e855,
  'video_settings': e857,
  'view_sidebar': e858,
  'wash': e85a,
  'wheelchair_pickup': e85c,
  'wifi_calling': e85d,
  'wifi_protected_setup': e85f,
  'wrong_location': e860,
  'wysiwyg': e861,
  'bento': e864,
  'carpenter': e866,
  'closed_caption_disabled': e868,
  'countertops': e86a,
  'east': e86c,
  'fence': e86d,
  'fire_extinguisher': e86f,
  'food_bank': e870,
  'foundation': e872,
  'grass': e874,
  'house_siding': e875,
  'leaderboard': e877,
  'microwave': e879,
  'near_me_disabled': e87b,
  'night_shelter': e87d,
  'no_meals': e87f,
  'no_transfer': e880,
  'north': e882,
  'north_east': e883,
  'north_west': e884,
  'qr_code_scanner': e885,
  'rice_bowl': e886,
  'roofing': e888,
  'set_meal': e88a,
  'south': e88c,
  'south_east': e88d,
  'south_west': e88e,
  'sports_bar': e88f,
  'sticky_note_2': e891,
  'tapas': e893,
  'water_damage': e895,
  'west': e897,
  'wine_bar': e898,
  '6_ft_apart': e89a,
  'book_online': e89b,
  'clean_hands': e89d,
  'connect_without_contact': e89f,
  'coronavirus': e8a0,
  'elderly': e8a2,
  'follow_the_signs': e8a3,
  'leave_bags_at_home': e8a5,
  'local_fire_department': e8a7,
  'local_police': e8a9,
  'masks': e8ab,
  'motion_photos_on': e8ad,
  'motion_photos_paused': e8ae,
  'reduce_capacity': e8af,
  'sanitizer': e8b0,
  'sick': e8b2,
  'add_task': e8b3,
  'contact_page': e8b4,
  'disabled_by_default': e8b6,
  'facebook': e8b8,
  'groups': e8b9,
  'luggage': e8bb,
  'motion_photos_pause': e8bd,
  'no_backpack': e8be,
  'no_luggage': e8c0,
  'outbond': e8c2,
  'published_with_changes': e8c4,
  'request_page': e8c5,
  'stacked_line_chart': e8c7,
  'unpublished': e8c8,
  'align_horizontal_center': e8ca,
  'align_horizontal_left': e8cb,
  'align_horizontal_right': e8cc,
  'align_vertical_bottom': e8cd,
  'align_vertical_center': e8ce,
  'align_vertical_top': e8cf,
  'horizontal_distribute': e8d0,
  'qr_code_2': e8d1,
  'update_disabled': e8d2,
  'vertical_distribute': e8d3,
  'add_reaction': e8d4,
  'add_to_drive': e8d6,
  'arrow_back_ios_new': e8d7,
  'change_circle': e8d8,
  'copy_all': e8da,
  'electric_rickshaw': e8dc,
  'health_and_safety': e8de,
  'outbound': e8e0,
  'production_quantity_limits': e8e2,
  'safety_divider': e8e3,
  'savings': e8e4,
  'social_distance': e8e6,
  'task_alt': e8e7,
  'travel_explore': e8e8
);

$sizes: (
  18,
  24,
  28,
  36,
  48
);

@function content($codepoint) {
  @return unquote("'\\#{$codepoint}'");
}

@mixin font($family, $filename) {
  @font-face {
    font-family: $family;
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("#{$font-path}#{$filename}.ttf");
    /* For IE6-8 */
    src: url("#{$font-path}#{$filename}.woff2") format("woff2"),
    url("#{$font-path}#{$filename}.woff") format("woff"),
    url("#{$font-path}#{$filename}.ttf") format("truetype");
  }
}

@include font($default-family, $default-filename);

@each $theme, $item in $extra-themes {
  $family: nth($item, 1);
  $filename: nth($item, 2);
  @include font($family, $filename);
}

.material-icons {
  font-family: $default-family;
  font-weight: normal;
  font-style: normal;
  font-size: 22px; 
  vertical-align:middle;
  display: inline-block;
  line-height: 0.9;
  text-transform: none;
  letter-spacing: normal;
  -ms-word-wrap: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;

  &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: .3;
  }
  &:after {
    opacity: 1;
    line-height:normal;
  }
}

@each $theme, $family in $extra-themes {
  .material-icons-#{$theme} {
    @extend .material-icons;
    font-family: nth($family, 1), $default-family;
  }
}

/* Rules for sizing the icon. */
@each $size in $sizes {
  .md-#{$size} {
    font-size: #{$size}px;
  }
}

/* Rules for using icons as black on a light background. */
.md-dark { color: rgba(0, 0, 0, 0.54); }
.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.md-light { color: rgba(255, 255, 255, 1); }
.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

/* Icons. */
.md {
  @each $icon, $codepoints in $icons {
    &-#{$icon} {
      @if (type-of($codepoints) == 'list') {
        &:after {
          content: content(nth($codepoints, 1));
        }
        @if (length($codepoints) > 1) {
          &:before {
            content: content(nth($codepoints, 2));
          }
        }
      } @else {
        &:after {
          content: content($codepoints);
        }
      }
    }
  }
}
