@import "variables-custom";

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.card-body {
    color: #18587A !important;
}

.menu-aside .submenu a {
    color: #18587A !important;
}

.content-main {
    background-color: $body-bg;
    min-height: 100vh;
}

.form-control {
    &:focus {
        box-shadow: 0 0 0 2px rgb(49 103 235 / 7%);
    }
}

.text-logo {
    color: #ea531a;
}
.bg-logo {
    background-color: #ea531a;
}

.artiestela-switch {
    line-height: 10px;
}

.ant-table-title {
    display: none;
}

.admin-dropdown-menu {
    .dropdown-item {
        padding: 0.8rem 2rem 0.8rem 0.65rem;
        color: #18587A !important;
        border-bottom: 1px solid #f7f7f7 !important;
        :last-child {
            border-bottom: none;
        }
    }
}

.scrollable-list-group {
    max-height: 500px;
    margin-bottom: 5px;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}
.card-header {
    padding: 0.8rem 1.3rem;
    font-size: 1em;
    font-weight: bold;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    border-radius: 50%;
}
.form-label {
    font-weight: 400;
    font-size: 1.2em;
}
.ant-select.is-invalid {
    border-color: #F30000;
}

.btn:focus {
    outline: none;
    box-shadow: none;
}

.art-bubbles-tab-content {
    background: #fff;
    border: 1px solid #f8f8f8;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 25px;
}

.art-auth-navbar {
    .main-header {
        .nav-link {
            &:hover, &:active {
                color: #fd7f6f!important;
            }
        }
    }
}

.premium-icon-container {
    position: relative;
    display: block;
    .cover-image {
        opacity: 0.8;
        transition: all 0.3s ease-in-out;
        &:hover {
            opacity: 100%;
        }
    }
    .premium-icon {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 99999999;
        opacity: 100%;
        height: 25px !important;
        width: 25px !important;
    }
}

.global-admin-alert-success, 
.global-admin-alert-error {
    padding: 15px 37px 15px 40px;
    font-size: 16px;
    border-radius: 0 !important;
    .ant-alert-icon {
        top: 20px !important;
    }
    .ant-alert-close-icon {
        top: 14px !important;
    }
}

.plan-facilities {
    list-style: none;
    li {
        margin-bottom: 10px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #777e91 !important;
        .fa {
            color: #45B26B !important;
            margin-right: 10px;
        }
    }
}

.strong {
    font-size: 15px;
    font-weight: 500;
}