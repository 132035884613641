/* --------------------- titles ---------------------- */
.section-heading{ margin-bottom:40px; margin-top:0;
	p{ max-width:600px; margin:auto; }
}
.section-title{ font-weight:500;   }

.title-text{margin-top:45px; margin-bottom:20px;}


.b{font-weight:600;}


// p:last-child{ margin-bottom:0  }


/* ==================  title helpers ================ */
.rtl{direction:rtl;}
.ltr{direction:ltr;}