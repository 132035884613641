
body.dark{
	background-color:$dark; color:$white;


    .bg-light { background-color:$gray-800 !important;  }

    .brand-wrap{
         .logo{ filter: brightness(2); }
    }
    .navbar-aside{
        background-color:$gray-800;
        border-right:1px solid $gray-700; 
    }

    /// menu-darken
    .menu-aside{
        .menu-item{
            .icon{
                color:rgba($gray-500, .7); 
            }
            &.active{
                .icon{
                    color:$white;
                }
                .menu-link{
                    background-color:$gray-700;
                }
            }
        }
        .menu-link{  color:$light;  }
        .menu-link:hover{ background-color:$gray-700; color:$light; }
        .submenu{
            background-color:$gray-800; border-color:$dark;
            a{ color:$gray-400; 
                &:hover{ color:$white;} 
            }
        }  
    }

    .main-header{
        background-color:$gray-800;
        border-bottom:1px solid $gray-800;
        .nav-item > a{ color: $white; }
        
        .nav-link:hover, .nav-link.active{
            background-color:$gray-700;  color: $white
        }
    }
    

    /// card-darken
    .card, .card-header{
        background-color:$gray-800; 
    }
    .card{ border:1px solid $gray-700 }
    .card-header{ border-color: $gray-700; }

    .box{
        background:$gray-800;
    }


    //table
    .table{
        --bs-table-hover-bg: black;
        --bs-table-hover-color:#fff;
        color:$white;
        border-color:$gray-700;
    }



    .form-control, .form-select{
        background-color:$dark; 
        border-color:$gray-700;
        color:$white;
    }

    .btn{ color:white; }
    .btn-light{ background-color:$gray-700; border-color:$gray-700; 
        color:$white;
        i{ color:$gray-200; }
        &:hover{ background-color:$gray-800;  border-color:lighten($gray-700, 5%);  }
    }


    //itemside
    .itemside{
        color: $white;
    }
    a.itemside{color: $white;}

    // nav-pills
    .nav-pills{
        .nav-link{ color:$white; 
            &:hover{ color:$white;  }
        }
        .nav-link.active{ color:$white; background-color:$gray-700;  }
    }


    .card-product-grid{
        .title{ color:$white;}
    }

}
// body.dark end
